import {
  Button,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogTitle,
  Grid,
  Backdrop
} from '@material-ui/core';
import { useMutation } from 'react-apollo';
import { SubmissionError, Field, Form, reduxForm } from 'redux-form';

import { adminPantrySites } from '../apollo';
import updatePantry from '../apollo/mutations/updatePantry';
import getErrorMessage from '../helpers/getErrorMessage';
import { normalizePhone } from '../helpers/normalizers';
import { parseNumber } from '../helpers/parsers';
import {
  required,
  validPhoneNumber,
  validZipCode,
} from '../helpers/validators';
import RadioButtonGroup from './form-fields/RadioButtonGroup';
import TextField from './form-fields/TextField';
import { omit } from 'lodash';

const EditPantryForm = reduxForm({ form: 'editPantryForm' })((props: any) => {
  const {
    handleSubmit,
    submitting,
    onClose,
    registerFunction,
  } = props;

  return (
    <>
      <Backdrop invisible open={submitting} style={{ zIndex: 99 }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Form autoComplete="off" onSubmit={handleSubmit(registerFunction)}>
        <DialogTitle className="notranslate">
          Edit Pantry
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Field
                name="Name"
                component={TextField}
                label="Name"
                required
                validate={[required]}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Field
                name="Address1"
                component={TextField}
                label="Address 1"
                required
                validate={[required]}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Field
                name="Address2"
                component={TextField}
                label="Address2"
                required
                validate={[required]}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Field
                name="Zip"
                component={TextField}
                label="Zip"
                required
                validate={[required, validZipCode]}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Field
                name="Phone"
                component={TextField}
                label="Phone"
                required
                validate={[required, validPhoneNumber]}
                normalize={normalizePhone}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Field
                name="Contact"
                component={TextField}
                label="Contact"
                validate={[required]}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Field
                name="ExternalAllowed"
                component={TextField}
                label="External Allowed"
                validate={[required]}
                fullWidth
                parse={parseNumber}
                required
                inputmode="numeric"
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Field
                name="Year"
                component={TextField}
                label="Year"
                validate={[required]}
                fullWidth
                required
                parse={parseNumber}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Field
                name="ActiveAgencyFlag"
                label="ActiveAgencyFlag:"
                component={RadioButtonGroup}
                validate={[required]}
                required
                options={['Yes', 'No']}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Field
                name="SYHFlag"
                label="SYHFlag:"
                component={RadioButtonGroup}
                validate={[required]}
                required
                options={['Yes', 'No']}
              />
            </Grid>
          </Grid>
          <DialogActions
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 32 }}
          >
            <Button
              onClick={onClose}
              color="primary"
              disabled={submitting}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              disabled={submitting}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </Form>
    </>
  );
});

const EditPantry = (props: any) => {
  const [registerMutation] = useMutation(updatePantry, {
    onCompleted(response) {
      if (response && response.updatePantrySYH) {
        alert('Update Completed')
        props.onClose();
      } else {
        throw new SubmissionError({
          _error: 'Submission Error with the address, please try again.',
        });
      }
    },
    onError(error) {
      throw new SubmissionError({ _error: getErrorMessage(error) });
    },
    refetchQueries: [{ query: adminPantrySites }],
  });

  const registerFunction = (values: any) => {
    return registerMutation({
      variables: {
        input: {
          Pantry: props.selectedSite.Pantry,
          ...omit(values, ['Allowed','Lat','Long','Referals','availability','exceededLimitWarning','times', '__typename']),
          Zip: parseInt(values.Zip)
        },
      },
    });
  };

  let initialValues = {
    ...props.selectedSite
  } as any;

  return (
    <EditPantryForm
      {...props}
      registerFunction={registerFunction}
      initialValues={initialValues}
    />
  );
};

export default EditPantry;


import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { adminPantrySites } from '../../apollo';
import { Box, FormControl, IconButton, LinearProgress, TextField } from '@material-ui/core';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { EditOutlined } from '@material-ui/icons';
import EditPantry from '../EditPantry';
import RegistrationDialog from '../RegistrationDialog';


const PantryList = () => {
  const { data, error, loading } = useQuery(adminPantrySites);

  const [order, setOrder] = React.useState('');
  const [visibleRows, setVisibleRows] = React.useState([]);
  const [selectedSite, setSelectedSite] = useState<any>(null);

  useEffect(() => {
    if (!loading)
      setVisibleRows(data.adminPantrySites.pantries.filter((el:any) => el.Name.toLowerCase().includes(order.toLowerCase())))},
    [order, data]
  );

  const columns: GridColDef[] = [
    { field: 'Pantry', headerName: 'Pantry', width: 130 },
    { field: 'Name', headerName: 'Name', width: 130 }, 
    { field: 'Address1', headerName: 'Address 1', width: 130 },
    { field: 'Address2', headerName: 'Address 2', width: 130 }, 
    { field: 'Zip', headerName: 'Zip', width: 130 },
    { field: 'Phone', headerName: 'Phone', width: 130 }, 
    { field: 'Contact', headerName: 'Contact', width: 130 },
    { field: 'Referals', headerName: 'Referals', width: 130 }, 
    { field: 'ExternalAllowed', headerName: 'External Allowed', width: 130 },
    { field: 'SYHFlag', headerName: 'SYHFlag', width: 130 }, 
    { field: 'Year', headerName: 'Year', width: 130 },
    { field: 'ActiveAgencyFlag', headerName: 'Active Agency Flag', width: 130 }, 
    { field: 'Lat', headerName: 'Lat', width: 130 },
    { field: 'Long', headerName: 'Long', width: 130 }, 
    { field: 'Action', headerName: 'Action', width: 130, renderCell: (params) => {
      return (
        <IconButton onClick={() => setSelectedSite(params.row)} aria-label="delete" color="primary">
          <EditOutlined />
        </IconButton>
      );
    },  }, 
  ];

  return ( !loading ? 
        <Box>
          <Box padding={2}><FormControl fullWidth><TextField onChange={(e) => setOrder(e.target.value)} id="outlined-basic" label="Search" variant="outlined" /></FormControl></Box>
          <DataGrid
            checkboxSelection={false}
            getRowId={(row) => row.Pantry}
            rows={visibleRows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 100 },
              },
            }}
            pageSizeOptions={[50, 100]}
          />
          <RegistrationDialog
            open={selectedSite ? true: false}
            onClose={() => setSelectedSite(null)}
            disableBackdropClick
            disableEscapeKeyDown
          >
            <EditPantry
              onClose={() => setSelectedSite(null)}
              selectedSite={selectedSite}
            />
          </RegistrationDialog>
        </Box>:
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
  );
};

export default PantryList;

import gql from 'graphql-tag';

export default gql`
  mutation($input: PantryReservationInput!) {
    createPantryReservation(input: $input) {
      pantryName
      pantryAddress
      pantryContact
      pantryPhone
      selectedTime
      name
      email
      address
      city
      state
      zip
      homePhone
      workPhone
      idType
      numberAdults
      numberChildren
      comments,
      preferredContactMethod,
      idReferal
    }
  }
`;

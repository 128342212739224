
import React, { useEffect } from 'react';
import { Mutation, useQuery } from 'react-apollo';
import { deletePantryReservation, reservations } from '../../apollo';
import { Box, Button, FormControl, Grid, IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Typography } from '@material-ui/core';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import ConfirmDialog from '../confirm-dialog/confirm-dialog';
import { RefreshOutlined } from '@material-ui/icons';
import { randomUUID } from 'crypto';


const ReservationList = () => {
  const { data, error, loading, refetch } = useQuery(reservations, { notifyOnNetworkStatusChange: true });
  const [order, setOrder] = React.useState('');
  const [visibleRows, setVisibleRows] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);

  useEffect(() => {
    if (!loading)
      setVisibleRows(data.reservations.filter((el:any) => el.FirstName.toLowerCase().includes(order.toLowerCase()))
      .map((el:any) => ({...el, randomID: generateRandom()})))},
    [order, data]
  );

  const closeDialog = () => setOpenDialog(false);
  const columns: GridColDef[] = [
    { field: 'Key_ID', headerName: 'Key ID', width: 130 },
    { field: 'FirstName', headerName: 'First name', width: 130 },
    { field: 'LastName', headerName: 'Last name', width: 130 }, 
    { field: 'ID', headerName: 'ID Type', width: 130 }, 
    { field: 'Address', headerName: 'Address', width: 500 }, 
    { field: 'Email', headerName: 'Email', width: 300 }, 
    { field: 'City', headerName: 'City', width: 150 }, 
    { field: 'State', headerName: 'State', width: 50 }, 
    { field: 'Zip', headerName: 'Zip', width: 80 }, 
    { field: 'HomePhone', headerName: 'Home Phone', width: 120 }, 
    { field: 'WorkPhone', headerName: 'Work Phone', width: 120 }, 
    { field: 'Volunteer', headerName: 'Volunteer', width: 80,
    renderCell: (params) => {
        return (
            <Typography>
                {params.row.Volunteer ? 'Yes': 'No'}
            </Typography>
        );
      }, 
    }, 
    { field: 'ServeDate', headerName: 'Serve Date', width: 300 }, 
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      sortable: false,
      renderCell: (params) => {
          return (
              <>
                  <Button
                      onClick={() => setOpenDialog(params.row.Key_ID)}
                      color={'secondary'}
                      variant={'contained'}
                      size={'small'}
                  >
                      Delete
                  </Button>
              </>
          );
      },
    },
  ];

  const  generateRandom = () => {
    var length = 12,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const showDialog = (
    <Mutation
      mutation={deletePantryReservation}
      variables={{ input: { Key_ID: openDialog } }}
      refetchQueries={[
        { query: reservations },
      ]}
      onError={(mutationError:any) => alert(mutationError)}
      onCompleted={() => {
        const copy = [...visibleRows];
        setVisibleRows(copy.filter((el:any) => el.Key_ID != openDialog));
        setOpenDialog(false);
        alert('Delete Successful');
      }}
      
    >
      {(deletePantryReservation:any,loading:any) => {

        return (
          <ConfirmDialog loading={loading} close={closeDialog} confirm={deletePantryReservation}>
            <Typography>
                Are you sure you want to delete?
            </Typography>
          </ConfirmDialog>
        );
      }}
    </Mutation>);

  return ( !loading ? 
        <Box>
          <>
          <Grid container spacing={2}>
            <Grid item xs={11}>
            <Box padding={2}><FormControl fullWidth><TextField onChange={(e) => setOrder(e.target.value)} id="outlined-basic" label="Search" variant="outlined" /></FormControl></Box>
            </Grid>
            <Grid  item xs={1}>
            <IconButton style={{marginBlockStart:25}} onClick={() => {
              refetch()}} aria-label="delete">
              <RefreshOutlined fontSize='medium' />
            </IconButton>
            </Grid>
          </Grid>
          <DataGrid
            getRowId={(row) => row.randomID}
            rows={visibleRows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 100 },
              }
            }}
            pageSizeOptions={[50, 100]}
            checkboxSelection={false}
          />
          {openDialog && showDialog}
          </>
        </Box>:
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
  );
};

export default ReservationList;

import gql from 'graphql-tag';

export default gql`
  query {
    reservations {
      Key_ID
      FirstName
      LastName
      ID
      Address
      City
      State
      Zip
      ServeDate
      HomePhone
      WorkPhone
      Email
      Volunteer
    }
  }
`;
